import Snackbar from '@mui/material/Snackbar';
import AlertMUI from '@mui/material/Alert';
import React from 'react';
import useMedia from 'use-media';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <AlertMUI elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function AlertBar(props) {
  const isMobile = !useMedia({ minWidth: '600px' });

  const message = props.message;
  const handleClose = props.onClose;
  const open = props.open;
  const status = props.status;

  const position = isMobile ? { top: 'auto', bottom: 96 } : {};

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} sx={position}>
      <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
