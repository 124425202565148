import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import NextDayIcon from '../../icons/NextDayIcon';

export default function MobileRow(props) {
  const { row, openEditModal, handleDateColor, month, canEdit } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(false);
  }, [month]);

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ p: 0, borderBottom: 'unset' }}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{handleDateColor(row.attributeOfDay, row.date)}</TableCell>
        {(() => {
          if (row.publicHoliday) {
            return <TableCell>公休</TableCell>;
          } else if (row.paidHoliday) {
            return <TableCell>有給</TableCell>;
          } else {
            return (
              <TableCell>
                {row.startTime ? `${row.startTime} ~ ${row.endTime ?? ''}` : ''}
              </TableCell>
            );
          }
        })()}
        <TableCell align='right'>
          {canEdit() && (
            <IconButton
              aria-label='edit'
              onClick={() => {
                openEditModal(row);
              }}>
              <EditIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 0, mb: 2 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  <TableRow>
                    <TableCell>出勤時間</TableCell>
                    <TableCell>{row.startTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>退勤時間</TableCell>
                    <TableCell>
                      <Box display='flex' flexDirection='row'>
                        {row.endTime}
                        {row.isLeavedTomorrow && <NextDayIcon />}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>休憩時間</TableCell>
                    <TableCell>{row.breakTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>実労働時間</TableCell>
                    <TableCell>{row.actualWorkingTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>コメント</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: 120,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>
                        {row.comment}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
