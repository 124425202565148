/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { authenticate } from '../services/cognitoService';
import { useNavigate } from 'react-router-dom';
import { StoreProvider } from '..';

export default function SignIn({ initialize }) {
  const { setIsLoading } = React.useContext(StoreProvider);
  const [email, setEmail] = React.useState();
  const [passwd, setPasswd] = React.useState();
  const navigate = useNavigate();
  const [error, setError] = React.useState();

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await authenticate(email, passwd);
      // 管理者権限かどうかをStateに反映させる
      initialize();
      // トップページへ
      navigate('/');
    } catch (e) {
      console.error(e);
      setError('メールアドレスまたはパスワードに誤りがあります');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Box
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 4,
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          ログイン
        </Typography>
        <p>
          <span style={{ color: 'red' }}>{error}</span>
        </p>

        <Box component='form' noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='メールアドレス'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='パスワード'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={(event) => setPasswd(event.target.value)}
          />
          <Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleClick}>
            ログイン
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
