import { Navigate } from 'react-router-dom';
import { useAuth } from '../../services/clientService';

/**
 * ログイン済みによるルート制御
 */
export default function AuthGuard(props) {
  const { component } = props;
  const isAuth = useAuth();

  return isAuth ? <>{component}</> : <Navigate replace to='/signIn' />;
}
