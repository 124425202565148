import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { StoreProvider } from '..';
import { createUserApi } from '../services/apiService';

export function CreateUserModal(props) {
  const dateStr = dayjs().startOf('day').format('YYYY-MM-DD ');
  const { handleAlertOpen, open, setOpen, setUserRows, month } = props;
  const { setIsLoading } = React.useContext(StoreProvider);
  // react-hook-formの設定
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [userValues, setUserValues] = React.useState({
    name: '',
    email: '',
    role: '',
    defaultStartTime: '',
    defaultEndTime: '',
    defaultBreakTime: '',
    hireDate: dayjs().format('YYYY-MM-DD'),
  });
  // 登録完了モーダル設定
  const [completeModalValues, setCompleteModalValues] = React.useState({
    open: false,
    userId: '',
    password: '',
  });

  const submitHandler = async () => {
    setIsLoading(true);
    try {
      // 従業員追加
      const createdUserInfo = await createUserApi(userValues);

      // 従業員追加モーダルを閉じる
      setOpen(false);

      // ユーザーリストの更新
      await setUserRows(month);

      // 完了モーダルオープン
      setCompleteModalValues({
        open: true,
        userId: createdUserInfo.userId,
        password: createdUserInfo.password,
      });
    } catch (e) {
      console.error(e);
      handleAlertOpen('従業員の追加に失敗しました。', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const setHandler = (event) => {
    setUserValues({ ...userValues, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>従業員追加</DialogTitle>
        <DialogContent>
          <Grid container alignItems='center' justify='center'>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                label='氏名'
                fullWidth
                value={userValues.name}
                error={'name' in errors}
                helperText={errors?.name?.message}
                inputProps={{ maxLength: 50 }}
                {...register('name', {
                  required: '入力してください。',
                  onChange: (event) => setHandler(event),
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type='email'
                margin='dense'
                label='メールアドレス'
                fullWidth
                value={userValues.email}
                error={'email' in errors}
                helperText={errors?.email?.message}
                {...register('email', {
                  required: '入力してください。',
                  pattern: {
                    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                    message: '入力形式がメールアドレスではありません。',
                  },
                  onChange: (event) => setHandler(event),
                })}
              />
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm={8}>
                <TextField
                  type='date'
                  margin='dense'
                  label='入社日'
                  fullWidth
                  value={userValues.hireDate}
                  error={'hireDate' in errors}
                  helperText={errors?.hireDate?.message}
                  {...register('hireDate', {
                    required: '入力してください。',
                    onChange: (event) => setHandler(event),
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label='管理者権限'
                  fullWidth
                  value={userValues.role}
                  error={'role' in errors}
                  helperText={errors?.role?.message}
                  {...register('role', {
                    required: '選択してください。',
                    onChange: (event) => setHandler(event),
                  })}
                  sx={{ mt: 1 }}>
                  <MenuItem value={0}>無し</MenuItem>
                  <MenuItem value={1}>有り</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ mt: 1 }} spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  name='defaultStartTime'
                  label='現場出勤時間'
                  type='time'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  value={userValues.defaultStartTime}
                  error={'defaultStartTime' in errors}
                  helperText={errors?.defaultStartTime?.message}
                  {...register('defaultStartTime', {
                    required: '入力してください。',
                    onChange: (event) => setHandler(event),
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label='現場退勤時間'
                  type='time'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  value={userValues.defaultEndTime}
                  error={'defaultEndTime' in errors}
                  helperText={errors?.defaultEndTime?.message}
                  {...register('defaultEndTime', {
                    validate: (value) => {
                      const start = dayjs(dateStr + userValues.defaultStartTime).valueOf();
                      const end = dayjs(dateStr + value).valueOf();
                      return start < end || '設定時間に誤りがあります。';
                    },
                    required: '入力してください。',
                    onChange: (event) => setHandler(event),
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label='現場休憩時間'
                  type='time'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 300 }}
                  value={userValues.defaultBreakTime}
                  error={'defaultBreakTime' in errors}
                  helperText={errors?.defaultBreakTime?.message}
                  {...register('defaultBreakTime', {
                    validate: (value) => {
                      const start = dayjs(dateStr + userValues.defaultStartTime);
                      const end = dayjs(dateStr + userValues.defaultEndTime);
                      const workTime = end
                        .subtract(start.hour(), 'h')
                        .subtract(start.minute(), 'm');
                      const breakTime = dayjs(dateStr + value);
                      // 労働時間を休憩時間が超過していたらエラー
                      return workTime.isAfter(breakTime) || '設定時間に誤りがあります。';
                    },
                    required: '入力してください。',
                    onChange: (event) => setHandler(event),
                  })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 1.5 }}>
          <Grid justify='space-between' container>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setOpen(false)}
                sx={{ width: 125 }}>
                キャンセル
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(submitHandler)}
                sx={{ width: 125 }}>
                登録
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <CompleteModal values={completeModalValues} setValues={setCompleteModalValues} />
    </>
  );
}

function CompleteModal(props) {
  const { values, setValues } = props;
  const closeHandler = () => {
    setValues({ ...values, open: false });
  };

  return (
    <Dialog open={values.open} onClose={closeHandler} fullWidth>
      <DialogTitle>従業員の追加が完了しました。</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'red', p: 1 }}>
          このモーダルウィンドウを閉じると、パスワードの確認は行えなくなるので必ずメモしてください。
        </DialogContentText>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label='ユーザーID'
              defaultValue={values.userId}
              InputProps={{
                readOnly: true,
              }}
              variant='standard'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label='パスワード'
              defaultValue={values.password}
              InputProps={{
                readOnly: true,
              }}
              variant='standard'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
