import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { getRelativeHeight, useWindowSize } from '../../services/clientService';
import { MainBottomProvider } from '../App';

export default function AttendStats(props) {
  // 画面サイズ
  const height = useWindowSize();
  // 統計データ要素
  const dataRef = React.useRef(null);
  // 統計データ要素の高さ
  const [dataHeight, setDataHeight] = React.useState(0);
  // Bottom取得
  const { mainBottom } = React.useContext(MainBottomProvider);

  React.useEffect(() => {
    setDataHeight(getRelativeHeight(dataRef, height, mainBottom));
  }, [dataRef, height, mainBottom]);

  return (
    <Grid container justifyContent='center' alignContent='center'>
      <Grid item xs={12}>
        <TableContainer ref={dataRef} sx={{ height: dataHeight }}>
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>氏名</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{props.values?.name ?? ''}</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>所定日数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.prescribedDateSum ?? ''} 日
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>出勤日数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.attendanceDateSum ?? ''} 日
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>欠勤日数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.nonattendanceDateSum ?? ''} 日
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>早退回数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.earlyLeaveDateSum ?? ''} 回
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>遅刻回数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.lateAttendDateSum ?? ''} 回
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>実労働時間</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.totalActualWorkingTime ?? ''}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>残業時間</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.totalOverTime ?? ''}
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>休日出勤日数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.holidayAttendanceSum ?? ''} 日
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>月の有給使用数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.usedPaidHolidayCount ?? ''} 回
                </TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell sx={{ textAlign: 'center', width: '40%' }}>有給残数</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {props.values?.usablePaidHolidayCount ?? ''} 回
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
