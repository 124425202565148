import React from 'react';
import { isAuth } from './cognitoService';

/**
 * ログインしているか
 */
export const useAuth = () => {
  const [auth, setAuth] = React.useState(isAuth());

  React.useEffect(() => {
    setAuth(isAuth());
  }, []);
  return auth;
};

/**
 * 画面サイズの取得
 */
export const useWindowSize = () => {
  const [size, setSize] = React.useState(0);
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize(window.innerHeight);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

/**
 * 相対的高さの取得
 */
export const getRelativeHeight = (ref, windowHeight, bottom) => {
  const prop = ref.current?.getBoundingClientRect();
  if (!prop || !windowHeight) return 0;

  const relativeHeight = windowHeight - prop.top - bottom;
  if (relativeHeight < 0) return 0;

  return relativeHeight;
};
