import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function ConfirmDialog({ title, text, rightButtonText, stateTuple, onSubmit }) {
  const [open, setOpen] = stateTuple;
  const handleClose = () => {
    setOpen(false);
  };
  const clickHandler = () => {
    setOpen(false);
    onSubmit();
  };
  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 1350 }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size='small' variant='text' onClick={handleClose}>
          キャンセル
        </Button>
        <Button size='small' variant='text' onClick={clickHandler} autoFocus>
          {rightButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
