import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MainListItems, SecondaryListItems } from './listItems';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useWindowSize } from '../services/clientService';
import HeaderLogo from './HeaderLogo';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: '#fff',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

export const MainBottomProvider = React.createContext();

export default function App({ children }) {
  const [open, setOpen] = React.useState(false);
  const [mainBottom, setMainBottom] = React.useState('');
  const headerRef = React.useRef(56);
  const [headerHeight, setHeaderHeight] = React.useState(65);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isMobileSize = !useMediaQuery('(min-width:600px)');
  // 画面サイズ
  const height = useWindowSize();
  // 高さを計算する対象
  const footerRef = React.useRef();
  const [footerHeight, setFooterHeight] = React.useState();

  React.useEffect(() => {
    // モバイルサイズの場合フッターのトップをボトムに設定
    const footer = footerRef.current?.getBoundingClientRect();
    setMainBottom(isMobileSize ? height - footer?.top : 0);
  }, [footerRef, height, isMobileSize]);

  React.useEffect(() => {
    const clientRect = headerRef.current?.getBoundingClientRect();
    setHeaderHeight(clientRect?.height);
  }, [headerRef]);

  React.useEffect(() => {
    const clientRect = footerRef.current?.getBoundingClientRect();
    setFooterHeight(clientRect?.height);
  }, [footerRef]);

  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      {isMobileSize ? (
        <>
          <MuiAppBar
            elevation={0}
            position='fixed'
            ref={headerRef}
            sx={{
              backgroundColor: '#fff',
              borderBottom: '1px solid #ddd',
            }}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
                justifyContent: 'space-between',
              }}>
              <HeaderLogo />
              <IconButton
                edge='end'
                color='primary'
                aria-label='open drawer'
                onClick={toggleDrawer}
                sx={{
                  ...(open && { display: 'none' }),
                }}>
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </MuiAppBar>
          <MuiDrawer
            anchor='right'
            open={open}
            onClose={toggleDrawer}
            PaperProps={{ style: { width: '80%' } }}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronRightIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component='nav'>
              {/* <MainListItems />
              <Divider sx={{ my: 1 }} /> */}
              <SecondaryListItems />
            </List>
          </MuiDrawer>
        </>
      ) : (
        <>
          <AppBar
            elevation={0}
            position='fixed'
            ref={headerRef}
            open={open}
            sx={{
              borderBottom: '1px solid #ddd',
            }}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}>
              <IconButton
                edge='start'
                color='primary'
                aria-label='open drawer'
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}>
                <MenuIcon />
              </IconButton>
              <HeaderLogo />
            </Toolbar>
          </AppBar>
          <Drawer variant='permanent' open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component='nav'>
              <MainListItems />
              <Divider sx={{ my: 1 }} />
              <SecondaryListItems />
            </List>
          </Drawer>
        </>
      )}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          height: '100dvh',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}>
        <Toolbar sx={{ backgroundColor: '#fff', height: headerHeight }} />

        <MainBottomProvider.Provider value={{ mainBottom }}>
          <Box sx={{ flexGrow: 2, display: 'flex', overflow: 'scroll' }}>{children}</Box>
        </MainBottomProvider.Provider>

        {isMobileSize && (
          <>
            <Toolbar sx={{ backgroundColor: '#fff', height: footerHeight }} />
            <MuiAppBar
              ref={footerRef}
              position='fixed'
              elevation={0}
              sx={{
                top: 'auto',
                bottom: 0,
                backgroundColor: '#fff',
                borderTop: '1px solid #ddd',
              }}>
              <Toolbar sx={{ pb: 'env(safe-area-inset-bottom)' }}>
                <MainListItems />
              </Toolbar>
            </MuiAppBar>
          </>
        )}
      </Box>
    </Box>
  );
}
