import axios from 'axios';
import { getJwt } from './cognitoService';

const origin =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : window.location.origin;

/**
 * 処理日・ユーザーIDから勤怠データを取得する
 * @param {number} execDate
 * @returns {Promise<object>} 勤怠データ
 */
export const getAttendanceApi = async () => {
  console.debug('--- getAttendanceApi: START ---');
  try {
    const apiUrl = origin + '/api/getAttendance';
    const result = await axios.get(apiUrl, {
      headers: {
        Authorization: getJwt(),
      },
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- getAttendanceApi: END ---');
  }
};

/**
 * 出勤打刻処理
 * @param {object} postData
 */
export const addAttendApi = async (postData) => {
  console.debug('--- addAttendApi: START ---');
  try {
    const apiUrl = origin + '/api/addAttend';
    await axios.post(apiUrl, postData, {
      headers: {
        Authorization: getJwt(),
      },
    });
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- addAttendApi: END ---');
  }
};

/**
 * 退勤処理
 * @param {object} postData
 */
export const addLeaveApi = async (postData) => {
  console.debug('--- addLeave: START ---');
  try {
    const apiUrl = origin + '/api/addLeave';
    await axios.post(apiUrl, postData, {
      headers: {
        Authorization: getJwt(),
      },
    });
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- addLeave: END ---');
  }
};

/**
 * 勤怠データの更新・登録
 * @param {object} data
 * @param {?string} userId
 */
export const putAttendanceApi = async (data, userId) => {
  console.debug('--- putAttendanceApi: START ---');
  try {
    let apiUrl = origin + '/api/putAttendance';
    if (userId) apiUrl += '?userId=' + userId;
    await axios.post(apiUrl, data, {
      headers: {
        Authorization: getJwt(),
      },
    });
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- putAttendanceApi: END ---');
  }
};

/**
 * ユーザー詳細情報の取得
 * @param {string} userId
 * @returns {object}
 */
export const getUserDetailApi = async () => {
  console.debug('--- getUserDetailApi: START ---');
  try {
    const apiUrl = origin + '/api/getUserDetail';
    const result = await axios.get(apiUrl, {
      headers: {
        Authorization: getJwt(),
      },
    });

    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- getUserDetailApi: END ---');
  }
};

/**
 * ユーザー標準出退勤時間の更新
 * @param {object} data
 */
export const updateDefaultTimeApi = async (data) => {
  console.debug('--- updateDefaultTimeApi: START ---');
  try {
    const apiUrl = origin + '/api/updateDefaultTime';
    await axios.post(apiUrl, data, {
      headers: {
        Authorization: getJwt(),
      },
    });
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- updateDefaultTimeApi: END ---');
  }
};

/**
 * ユーザー一覧取得
 * @param {string} execMonth
 * @returns {Promise<array>}
 */
export const getAllUsersApi = async (execMonth) => {
  console.debug('--- getAllUsersApi: START ---');
  try {
    const apiUrl = origin + '/api/getAllUsers?execMonth=' + execMonth;
    const result = await axios.get(apiUrl, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- getAllUsersApi: END ---');
  }
};

/**
 * 勤怠データを含む１ヶ月の日付リストの取得
 * @param {string} month YYYY-MM | timestamp
 * @returns {Promise<array>}
 */
export const getDateListAndStatsApi = async (month, userId) => {
  console.debug('--- getDateListAndStatsApi: START ---');
  try {
    let apiUrl = origin + '/api/getDateListAndStats?month=' + month;
    if (userId) apiUrl += '&userId=' + userId;
    const result = await axios.get(apiUrl, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- getDateListAndStatsApi: END ---');
  }
};

/**
 * ユーザー標準出退勤時間の更新
 * @param {object} data
 */
export const createUserApi = async (data) => {
  console.debug('--- createUserApi: START ---');
  try {
    const apiUrl = origin + '/api/createUser';
    const result = await axios.post(apiUrl, data, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- createUserApi: END ---');
  }
};

/**
 * ユーザー標準出退勤時間の更新
 * @param {object} data
 */
export const startBreakApi = async (data) => {
  console.debug('--- startBreakApi: START ---');
  try {
    const apiUrl = origin + '/api/startBreak';
    const result = await axios.post(apiUrl, data, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- startBreakApi: END ---');
  }
};

/**
 * ユーザー標準出退勤時間の更新
 * @param {object} data
 */
export const endBreakApi = async (data) => {
  console.debug('--- endBreakApi: START ---');
  try {
    const apiUrl = origin + '/api/endBreak';
    const result = await axios.post(apiUrl, data, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- endBreakApi: END ---');
  }
};

/**
 * 本日の出勤状況の取得
 * @returns {Promise<array>}
 */
export const getTodayAttendanceApi = async () => {
  console.debug('--- getTodayAttendanceApi: START ---');
  try {
    let apiUrl = origin + '/api/getTodayAttendance';
    const result = await axios.get(apiUrl, {
      headers: {
        Authorization: getJwt(),
      },
    });
    return result.data;
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- getTodayAttendanceApi: END ---');
  }
};

/**
 * 本日の出勤状況の取得
 * @param {string} attendanceId
 * @returns {Promise<void>}
 */
export const deleteAttendanceApi = async (attendanceId) => {
  console.debug('--- deleteAttendanceApi: START ---');
  try {
    let apiUrl = origin + '/api/deleteAttendance';
    await axios.post(
      apiUrl,
      { attendanceId },
      {
        headers: {
          Authorization: getJwt(),
        },
      }
    );
  } catch (error) {
    if (error.response.status === 400) {
      throw error;
    }
  } finally {
    console.debug('--- deleteAttendanceApi: END ---');
  }
};
