import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { EditModal } from './parts/EditModal';
import { useMedia } from 'use-media';
import { getRelativeHeight, useWindowSize } from '../../services/clientService';
import { deleteAttendanceApi, putAttendanceApi } from '../../services/apiService';
import { StoreProvider } from '../..';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { MainBottomProvider } from '../App';
import NextDayIcon from '../icons/NextDayIcon';
import MobileRow from './parts/MobileRow';
dayjs.extend(isSameOrBefore);

function CommentCell(props) {
  const textRef = React.useRef(null);
  const [truncated, setTruncated] = React.useState(false);

  // 文字列が省略されているか
  const isTruncate = () => {
    setTruncated(textRef.current?.scrollWidth > 240);
  };

  React.useEffect(() => {
    isTruncate();
  }, [textRef]);

  return (
    <Tooltip title={truncated ? props.comment : ''}>
      <Box
        ref={textRef}
        sx={{
          m: 0,
          maxWidth: 240,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {props.comment}
      </Box>
    </Tooltip>
  );
}

export default function AttendTable(props) {
  const { setIsLoading, isAdmin } = React.useContext(StoreProvider);
  const isMobile = !useMedia({ minWidth: '600px' });
  // レスポンシブテーブルのデータ詳細開閉
  const [modalOpen, setModalOpen] = React.useState(false);
  // モーダル用
  const [modalValues, setModalValues] = React.useState({
    attendanceId: null,
    startTime: null,
    endTime: null,
    breakTime: null,
    execDate: null,
    comment: null,
  });
  // 1: 公休 2: 有給
  const [selectHoliday, setSelectHoliday] = React.useState('');
  // 画面サイズ
  const height = useWindowSize();
  // テーブル要素
  const tableRef = React.useRef(null);
  // テーブル高さ
  const [tableHeight, setTableHeight] = React.useState(0);
  // クエリパラメータ
  const [searchParams] = useSearchParams();
  // Bottom取得
  const { mainBottom } = React.useContext(MainBottomProvider);

  const canEdit = () => {
    const currentMonth = dayjs().startOf('month');
    const targetMonth = dayjs(props.month).startOf('month');
    return currentMonth.isSameOrBefore(targetMonth) || isAdmin;
  };

  const openEditModal = (data) => {
    // 値のセット
    setModalValues({
      ...modalValues,
      attendanceId: data.attendanceId,
      startTime: data.startTime,
      endTime: data.endTime,
      breakTime: data.breakTime,
      execDate: data.date,
      comment: data.comment,
      isLeavedTomorrow: data.isLeavedTomorrow ?? false,
    });

    let _selectHoliday = '';
    if (data.publicHoliday) _selectHoliday = 1;
    else if (data.paidHoliday) _selectHoliday = 2;
    else if (data.halfPaidHoliday) _selectHoliday = 3;
    setSelectHoliday(_selectHoliday);
    setModalOpen(true);
  };

  // 日祝・土・平日で表示する色を分ける
  const handleDateColor = (attributeOfDay, date) => {
    // 土曜
    const SATURDAY = 1;
    // 日曜
    const SUNDAY = 2;
    // 祝日
    const HOLIDAY = 3;

    // 日曜・祝日の場合：red
    if (attributeOfDay === SUNDAY || attributeOfDay === HOLIDAY) {
      return <div style={{ color: 'red' }}>{dayjs(date).format('DD(ddd)')}</div>;
    }
    // 土曜の場合：blue
    if (attributeOfDay === SATURDAY) {
      return <div style={{ color: '#1976D2' }}>{dayjs(date).format('DD(ddd)')}</div>;
    }
    return <div>{dayjs(date).format('DD(ddd)')}</div>;
  };

  /**
   * 勤怠データの更新・登録
   */
  const putAttendance = async () => {
    setIsLoading(true);
    try {
      await callPutAttendanceApi();

      setModalOpen(false);
      props.setup();
      props.handleAlertOpen('勤怠の登録・更新に成功しました', 'success');
    } catch (e) {
      console.error(e);
      props.handleAlertOpen('勤怠の登録・更新に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 勤怠登録・更新APIの呼び出し
   */
  const callPutAttendanceApi = async () => {
    const data = {
      attendanceId: modalValues.attendanceId,
      startTime: modalValues.startTime,
      endTime: modalValues.endTime,
      breakTime: modalValues.breakTime,
      execDate: modalValues.execDate.valueOf(),
      comment: modalValues.comment,
      isLeavedTomorrow: modalValues.isLeavedTomorrow,
      holiday: selectHoliday === '' ? undefined : selectHoliday,
    };

    await putAttendanceApi(data, searchParams.get('userId'));
  };

  /**
   * 勤怠データの削除APIの呼び出し
   */
  const deleteAttendance = async (attendanceId) => {
    setIsLoading(true);
    try {
      await deleteAttendanceApi(attendanceId);
      props.setup();
    } catch (e) {
      props.handleAlertOpen('勤怠データの削除に失敗しました。', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setTableHeight(getRelativeHeight(tableRef, height, mainBottom));
  }, [tableRef, height, mainBottom]);

  return (
    <>
      {isMobile ? (
        <TableContainer ref={tableRef} sx={{ height: tableHeight }}>
          <Table size='small'>
            <TableBody>
              {(() => {
                const data = [];
                for (const [index, row] of Object.entries(props.dateRows)) {
                  data.push(
                    <MobileRow
                      key={index}
                      row={row}
                      openEditModal={openEditModal}
                      handleDateColor={handleDateColor}
                      month={props.month}
                      canEdit={canEdit}
                    />
                  );
                }
                return data;
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer ref={tableRef} sx={{ height: tableHeight }}>
          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>日付</TableCell>
                <TableCell>出勤</TableCell>
                <TableCell>退勤</TableCell>
                <TableCell>休憩</TableCell>
                <TableCell>実労働</TableCell>
                <TableCell>休暇</TableCell>
                <TableCell>コメント</TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                const data = [];
                for (const [index, row] of Object.entries(props.dateRows)) {
                  data.push(
                    <TableRow key={index}>
                      <TableCell>{handleDateColor(row.attributeOfDay, row.date)}</TableCell>
                      <TableCell>{row.startTime}</TableCell>
                      <TableCell>
                        <Box display='flex' flexDirection='row'>
                          {row.isLeavedTomorrow && <NextDayIcon />}
                          {row.endTime}
                        </Box>
                      </TableCell>
                      <TableCell>{row.breakTime}</TableCell>
                      <TableCell>{row.actualWorkingTime}</TableCell>
                      <TableCell>
                        {row.publicHoliday != null && '公休'}
                        {row.paidHoliday != null && '有給'}
                        {row.halfPaidHoliday != null && '半休(有給)'}
                      </TableCell>
                      <TableCell>
                        <CommentCell comment={row.comment} />
                      </TableCell>
                      <TableCell align='right'>
                        {canEdit() && (
                          <IconButton
                            aria-label='edit'
                            onClick={() => {
                              openEditModal(row);
                            }}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
                return data;
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditModal
        openTuple={[modalOpen, setModalOpen]}
        stateTuple={[modalValues, setModalValues]}
        selectHolidayTuple={[selectHoliday, setSelectHoliday]}
        putAttendance={putAttendance}
        handleAlertOpen={props.handleAlertOpen}
        deleteAttendance={deleteAttendance}
      />
    </>
  );
}
