import React from 'react';
import App, { MainBottomProvider } from '../components/App';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StoreProvider } from '..';
import { getTodayAttendanceApi } from '../services/apiService';
import AlertBar from '../components/AlertBar';
import { useNavigate } from 'react-router-dom';
import { getRelativeHeight, useWindowSize } from '../services/clientService';
import { Collapse, IconButton, TableContainer, colors } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMedia } from 'use-media';

const attendStatus = {
  ABSENT: 'ABSENT', // 未打刻
  ON_ATTENDANCE: 'ON_ATTENDANCE', // 出勤中
  LEAVED: 'LEAVED', // 退勤済み
};

const statusToText = (status) => {
  switch (status) {
    case attendStatus.ABSENT:
      return '未打刻';
    case attendStatus.ON_ATTENDANCE:
      return '出勤中';
    case attendStatus.LEAVED:
      return '退勤済み';
    default:
      return '-';
  }
};

const statusToColor = (status) => {
  switch (status) {
    case attendStatus.ABSENT:
      return colors.red[500];
    case attendStatus.ON_ATTENDANCE:
      return colors.blue[500];
    case attendStatus.LEAVED:
      return colors.grey[500];
    default:
      return '#ddd';
  }
};

function RowTable(props) {
  const navigate = useNavigate();
  const { rows } = props;
  const isMobile = !useMedia({ minWidth: '600px' });
  // 画面サイズ
  const height = useWindowSize();
  // 高さを計算する対象
  const targetRef = React.useRef();
  // 要素の高さ
  const [targetHeight, setTargetHeight] = React.useState(0);
  // Bottom取得
  const { mainBottom } = React.useContext(MainBottomProvider);

  React.useEffect(() => {
    setTargetHeight(getRelativeHeight(targetRef, height, mainBottom));
  }, [targetRef, height, mainBottom]);

  return (
    <>
      {isMobile ? (
        <TableContainer ref={targetRef} sx={{ backgroundColor: '#FFF', height: targetHeight }}>
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <MobileRow key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer ref={targetRef} sx={{ backgroundColor: '#FFF', height: targetHeight }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>氏名</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>出勤時間</TableCell>
                <TableCell>退勤時間</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell>
                    <Link
                      color='primary'
                      onClick={() => navigate('/attendManagement?userId=' + row.userId)}
                      sx={{ cursor: 'pointer' }}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell sx={{ color: statusToColor(row.status) }}>
                    {statusToText(row.status)}
                  </TableCell>
                  <TableCell>{row.startTime}</TableCell>
                  <TableCell>{row.endTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function MobileRow(props) {
  const navigate = useNavigate();
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <Link color='primary' onClick={() => navigate('/attendManagement?userId=' + row.sub)}>
            {row.name}
          </Link>
        </TableCell>
        <TableCell sx={{ color: statusToColor(row.status), borderBottom: 'unset' }}>
          {statusToText(row.status)}
        </TableCell>
        <TableCell align='right' sx={{ p: '0 16px 0 0', borderBottom: 'unset' }}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 0, mb: 2 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  <TableRow>
                    <TableCell width={'100px'}>出勤時間</TableCell>
                    <TableCell>{row.startTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={'100px'}>退勤時間</TableCell>
                    <TableCell>{row.endTime}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function AttendanceStatus() {
  const navigate = useNavigate();
  const { setIsLoading, isAdmin } = React.useContext(StoreProvider);
  const [userRows, setUserRows] = React.useState([]);
  // アラート
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlertOpen(false);
    setMessage('');
  };

  const handleOpen = (msg, status) => {
    setAlertOpen(true);
    setMessage(msg);
    setAlertStatus(status);
  };

  const setup = async () => {
    setIsLoading(true);

    // 管理者権限がなければリダイレクト
    if (!isAdmin) return navigate('/');

    // ユーザーデータの設定
    await _setUserRows();

    setIsLoading(false);
  };

  const _setUserRows = async () => {
    try {
      const attendanceList = await getTodayAttendanceApi();
      const attendanceStatus = attendanceList.map((attendance) => {
        let status = attendStatus.ABSENT;
        if (attendance.endTime) {
          status = attendStatus.LEAVED;
        } else if (attendance.startTime) {
          status = attendStatus.ON_ATTENDANCE;
        }
        return {
          ...attendance,
          status: status,
        };
      });

      setUserRows(attendanceStatus);
    } catch (e) {
      console.error(e);
      handleOpen('ユーザー一覧情報を取得できませんでした', 'error');
    }
  };

  React.useEffect(() => {
    setup();
  }, []);

  return (
    <App>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: '#ffffff',
        }}>
        <RowTable rows={userRows} />
      </Box>

      <AlertBar message={message} onClose={handleClose} open={alertOpen} status={alertStatus} />
    </App>
  );
}
