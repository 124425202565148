import { Navigate } from 'react-router-dom';
import { useAuth } from '../../services/clientService';

/**
 * 未ログインルート制御
 */
export default function GuestGuard(props) {
  const { component } = props;
  const isAuth = useAuth();

  return !isAuth ? <>{component}</> : <Navigate replace to='/' />;
}
