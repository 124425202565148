import { Button, Grid, TextField } from '@mui/material';
import App from '../components/App';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getUserDetailApi, updateDefaultTimeApi } from '../services/apiService';
import AlertBar from '../components/AlertBar';
import { changePassword } from '../services/cognitoService';
import { StoreProvider } from '..';

export default function UserDetailConfig() {
  const { setIsLoading } = React.useContext(StoreProvider);
  // react-hook-formの設定
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  // 標準勤怠時間
  const [defaultStartTime, setDefaultStartTime] = React.useState('');
  const [defaultEndTime, setDefaultEndTime] = React.useState('');
  const [defaultBreakTime, setDefaultBreakTime] = React.useState('');
  // パスワード
  const [PWValues, setPWValues] = React.useState({ current: '', new: '', confirm: '' });
  // 標準勤怠時間バリデーションエラー
  const [defaultStartTimeError, setDefaultStartTimeError] = React.useState(false);
  const [defaultEndTimeError, setDefaultEndTimeError] = React.useState(false);
  const [defaultBreakTimeError, setDefaultBreakTimeError] = React.useState(false);
  // 現在のタブ
  const [tabValue, setTabValue] = React.useState('1');
  // アラート
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlertOpen(false);
    setMessage('');
  };

  const handleOpen = (msg, status) => {
    setAlertOpen(true);
    setMessage(msg);
    setAlertStatus(status);
  };

  // ユーザー詳細情報から標準出退勤時間を設定する
  const setDefaultAttendance = async () => {
    setIsLoading(true);
    try {
      const userDetail = await getUserDetailApi();

      setDefaultStartTime(userDetail.DefaultStartTime);
      setDefaultEndTime(userDetail.DefaultEndTime);
      setDefaultBreakTime(userDetail.DefaultBreakTime);
    } catch (e) {
      console.error(e);
      handleOpen('ユーザー詳細情報の取得に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const defaultAttendanceValidation = () => {
    // エラーメッセージの初期化
    setDefaultStartTimeError(false);
    setDefaultEndTimeError(false);
    setDefaultBreakTimeError(false);

    let flag = false;
    // 空白エラー
    if (defaultStartTime === '') {
      setDefaultStartTimeError(true);
      flag = true;
    }
    if (defaultEndTime === '') {
      setDefaultEndTimeError(true);
      flag = true;
    }
    if (defaultBreakTime === '') {
      setDefaultBreakTimeError(true);
      flag = true;
    }

    // 出勤時間 > 退勤時間 = エラー
    const _defaultStartTime = defaultStartTime.split(':');
    const _defaultEndTime = defaultEndTime.split(':');
    if (
      Number(_defaultStartTime[0] + _defaultStartTime[0]) >
      Number(_defaultEndTime[0] + _defaultEndTime[0])
    ) {
      setDefaultStartTimeError(true);
      setDefaultEndTimeError(true);
      flag = true;
    }
    return flag;
  };

  /** ユーザー標準出退勤時間の更新 */
  const updateDefaultAttendance = async () => {
    if (defaultAttendanceValidation()) return;

    setIsLoading(true);
    try {
      await callUpdateDefaultTime();

      handleOpen('ユーザー標準出退勤時間を更新しました', 'success');
    } catch (e) {
      console.error(e);
      handleOpen('ユーザー標準出退勤時間の更新に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const callUpdateDefaultTime = async () => {
    const data = {
      defaultStartTime: defaultStartTime,
      defaultEndTime: defaultEndTime,
      defaultBreakTime: defaultBreakTime,
    };

    await updateDefaultTimeApi(data);
  };

  const handleChangePW = (event) => {
    const key = event.target.getAttribute('name');
    setPWValues({ ...PWValues, [key]: event.target.value });
  };

  const updatePassword = async () => {
    setIsLoading(true);
    try {
      await changePassword(PWValues.current, PWValues.new);

      setPWValues({ ...PWValues, current: '', new: '', confirm: '' });
      handleOpen('パスワードを変更しました', 'success');
    } catch (e) {
      handleOpen(e, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // タブ切り替え
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    setDefaultAttendance();
  }, []);

  return (
    <App>
      <Box sx={{ width: '100%', backgroundColor: '#ffffff' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <TabList onChange={handleChangeTab} aria-label='lab API tabs example'>
              <Tab label='現場時間' value='1' />
              <Tab label='パスワード' value='2' />
            </TabList>
          </Box>

          {/* 現場時間設定パネル */}
          <TabPanel value='1' sx={{ p: 0 }}>
            <Grid container alignItems='center' justify='center' spacing={2} sx={{ p: 4 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id='defaultStartTime'
                  label='現場出勤時間'
                  type='time'
                  value={defaultStartTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  error={defaultStartTimeError}
                  onChange={(event) => setDefaultStartTime(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id='defaultEndTime'
                  label='現場退勤時間'
                  type='time'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  value={defaultEndTime}
                  onChange={(event) => setDefaultEndTime(event.target.value)}
                  error={defaultEndTimeError}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id='defaultBreakTime'
                  label='現場休憩時間'
                  type='time'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  value={defaultBreakTime}
                  onChange={(event) => setDefaultBreakTime(event.target.value)}
                  error={defaultBreakTimeError}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={updateDefaultAttendance}
                  sx={{ width: 125 }}>
                  更新
                </Button>
              </Grid>
            </Grid>
          </TabPanel>

          {/* パスワード設定パネル */}
          <TabPanel value='2' sx={{ p: 0 }}>
            <Grid container alignItems='center' justify='center' spacing={2} sx={{ p: 4 }}>
              <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                <TextField
                  required
                  fullWidth
                  value={PWValues.current}
                  name='current'
                  inputProps={{ maxLength: 32 }}
                  {...register('current', {
                    minLength: { value: 8, message: '8文字以上で入力してください。' },
                    required: '入力してください。',
                    onChange: (event) => handleChangePW(event),
                  })}
                  error={'current' in errors}
                  helperText={errors?.current?.message}
                  label='現在のパスワード'
                  type='password'
                  sx={{ maxWidth: 600 }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                <TextField
                  required
                  fullWidth
                  value={PWValues.new}
                  name='new'
                  inputProps={{
                    maxLength: 32,
                  }}
                  {...register('new', {
                    minLength: { value: 8, message: '8文字以上で入力してください。' },
                    required: '入力してください。',
                    pattern: {
                      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9]+$/,
                      message: '大文字英数字を含むパスワードを設定してください。',
                    },
                    onChange: (event) => handleChangePW(event),
                  })}
                  error={'new' in errors}
                  helperText={
                    errors?.new?.message
                      ? errors?.new?.message
                      : '大文字英数字を含む8文字以上でご入力ください。'
                  }
                  label='新しいパスワード'
                  type='password'
                  sx={{ maxWidth: 600 }}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                <TextField
                  required
                  fullWidth
                  value={PWValues.confirm}
                  name='confirm'
                  {...register('confirm', {
                    validate: (value) => value === PWValues.new || 'パスワードが一致しません。',
                    required: '入力してください。',
                    onChange: (event) => handleChangePW(event),
                  })}
                  error={'confirm' in errors}
                  helperText={errors?.confirm?.message}
                  label='パスワードの確認'
                  type='password'
                  sx={{ maxWidth: 600 }}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit(updatePassword)}
                  sx={{ width: 125 }}>
                  更新
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>

      <AlertBar message={message} onClose={handleClose} open={alertOpen} status={alertStatus} />
    </App>
  );
}
