import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

/**
 * cognito認証
 * @param {string} email
 * @param {string} passwd
 */
export const authenticate = async (email, passwd) => {
  return await new Promise((resolve, reject) => {
    // ログインを実施するユーザーの決定
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    // メールアドレス、パスワードの設定
    const authenticationData = { Username: email, Password: passwd };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );

    // 認証
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (data) => resolve(data.getAccessToken().getJwtToken()),
      onFailure: (err) => reject(err),
      newPasswordRequired(userAttributes, requiredAttributes) {
        // 仮パスワードを確定させる
        cognitoUser.completeNewPasswordChallenge(authenticationDetails.password, {}, this);
      },
    });
  });
};

/**
 * セッション情報からログインユーザー情報を取得する
 * @returns ログインユーザー情報
 */
export const getAuthUserInfo = async () => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) return null;
  const attributes = await new Promise((resolve, reject) => {
    // cognitoセッション情報の確認
    cognitoUser.getSession((err, session) => {
      if (err) return reject(err);
      // ユーザー属性の取得
      cognitoUser.getUserAttributes((err, result) => {
        if (err) return reject(err);
        return resolve(result);
      });
    });
  });
  // 取得した属性をオブジェクト化
  const userInfo = {};
  for (const attribute of attributes) {
    userInfo[attribute.getName()] = attribute.getValue();
  }
  return userInfo;
};

/**
 * セッションにあるJWTを取得する
 * @returns {object} jwt
 */
export const getJwt = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) throw Error('You are not Authenticated');
  return cognitoUser.getSession((error, session) => {
    if (error) throw error;
    return session.getIdToken().jwtToken;
  });
};

/**
 * ログアウト処理
 */
export const signOut = async () => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) throw Error('You are not Authenticated');
  await new Promise((resolve, reject) => {
    cognitoUser.signOut(() => resolve());
  });
};

/**
 * ログイン状態か
 */
export const isAuth = () => {
  const cognitoUser = userPool.getCurrentUser();
  return cognitoUser ? true : false;
};

/**
 * ユーザーパスワード変更
 * @param {string} currentPasswd
 * @param {string} newPasswd
 */
export const changePassword = async (currentPasswd, newPasswd) => {
  const userInfo = await getAuthUserInfo();

  // ユーザーの照合
  const userData = {
    Username: userInfo.email,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  // ログイン処理
  const authenticationData = {
    Username: userInfo.email,
    Password: currentPasswd,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

  await new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // パスワード変更処理
        cognitoUser.changePassword(currentPasswd, newPasswd, (err, result) => {
          if (err) {
            console.error(err);
            return reject('パスワードの更新に失敗しました');
          }
          resolve();
        });
      },

      onFailure: (err) => {
        console.error(err);
        reject('現在のパスワードが違います');
      },
    });
  });
};
