import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from '../services/cognitoService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { StoreProvider } from '..';

export const MainListItems = () => {
  const isMobileSize = !useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();
  const clickHandler = (path) => {
    navigate(path);
  };

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => clickHandler('/')}
        sx={{
          justifyContent: { xs: 'center', sm: 'left' },
        }}>
        <ListItemIcon sx={{ justifyContent: { xs: 'center', sm: 'left' } }}>
          {window.location.pathname === '/' ? <HomeIcon /> : <HomeOutlinedIcon />}
        </ListItemIcon>
        {!isMobileSize && <ListItemText primary='出退勤' />}
      </ListItemButton>
      <ListItemButton
        onClick={() => clickHandler('/attendManagement')}
        sx={{
          justifyContent: { xs: 'center', sm: 'left' },
        }}>
        <ListItemIcon sx={{ justifyContent: { xs: 'center', sm: 'left' } }}>
          {window.location.pathname === '/attendManagement' ? (
            <AccessTimeFilledOutlinedIcon />
          ) : (
            <AccessTimeIcon />
          )}
        </ListItemIcon>
        {!isMobileSize && <ListItemText primary='勤怠管理' />}
      </ListItemButton>
      <ListItemButton
        onClick={() => clickHandler('/userDetailConfig')}
        sx={{
          justifyContent: { xs: 'center', sm: 'left' },
        }}>
        <ListItemIcon sx={{ justifyContent: { xs: 'center', sm: 'left' } }}>
          {window.location.pathname === '/userDetailConfig' ? (
            <SettingsIcon />
          ) : (
            <SettingsIcon sx={{ strokeWidth: 2, stroke: 'rgba(0, 0, 0, 0.54)', color: '#fff' }} />
          )}
        </ListItemIcon>
        {!isMobileSize && <ListItemText primary='設定' />}
      </ListItemButton>
    </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  const navigate = useNavigate();
  const { isAdmin } = React.useContext(StoreProvider);

  const handleSignOut = async () => {
    await signOut();
    navigate('/signIn');
  };

  const clickHandler = (path) => {
    navigate(path);
  };
  return (
    <React.Fragment>
      {isAdmin && (
        <ListItemButton
          onClick={() => clickHandler('/userStats')}
          sx={{
            justifyContent: { xs: 'center', sm: 'left' },
          }}>
          <ListItemIcon>
            {window.location.pathname === '/userStats' ? <PeopleIcon /> : <PeopleAltOutlinedIcon />}
          </ListItemIcon>
          <ListItemText primary='従業員一覧' />
        </ListItemButton>
      )}
      {isAdmin && (
        <ListItemButton onClick={() => clickHandler('/attendanceStatus')}>
          <ListItemIcon>
            {window.location.pathname === '/attendanceStatus' ? <WorkIcon /> : <WorkOutlineIcon />}
          </ListItemIcon>
          <ListItemText primary='出勤状況' />
        </ListItemButton>
      )}
      <ListItemButton onClick={handleSignOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary='ログアウト' />
      </ListItemButton>
    </React.Fragment>
  );
};
