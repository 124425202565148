import { Box } from '@mui/material';

const NextDayIcon = () => {
  const sxProps = {
    color: '#fff',
    backgroundColor: '#1976d2',
    border: `solid 1px #1976d2`,
    borderRadius: '4px',
    fontSize: '12px',
  };
  return (
    <Box mx={1} sx={sxProps}>
      翌日
    </Box>
  );
};

export default NextDayIcon;
