import React from 'react';
import App, { MainBottomProvider } from '../components/App';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { StoreProvider } from '..';
import { getAllUsersApi } from '../services/apiService';
import AlertBar from '../components/AlertBar';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { getRelativeHeight, useWindowSize } from '../services/clientService';
import { Button, Collapse, IconButton, TableContainer } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMedia } from 'use-media';
import { CreateUserModal } from '../components/CreateUserModal';

function RowTable(props) {
  const navigate = useNavigate();
  const { rows, month } = props;
  const isMobile = !useMedia({ minWidth: '600px' });
  // 画面サイズ
  const height = useWindowSize();
  // 高さを計算する対象
  const targetRef = React.useRef();
  // 要素の高さ
  const [targetHeight, setTargetHeight] = React.useState(0);
  // Bottom取得
  const { mainBottom } = React.useContext(MainBottomProvider);

  React.useEffect(() => {
    setTargetHeight(getRelativeHeight(targetRef, height, mainBottom));
  }, [targetRef, height, mainBottom]);

  return (
    <>
      {isMobile ? (
        <TableContainer ref={targetRef} sx={{ backgroundColor: '#FFF', height: targetHeight }}>
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <MobileRow key={index} row={row} month={month} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer ref={targetRef} sx={{ backgroundColor: '#FFF', height: targetHeight }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>氏名</TableCell>
                <TableCell>所定日数</TableCell>
                <TableCell>欠勤日数</TableCell>
                <TableCell>休日出勤日数</TableCell>
                <TableCell>早退日数</TableCell>
                <TableCell>遅刻日数</TableCell>
                <TableCell>総実労働時間</TableCell>
                <TableCell>総残業時間</TableCell>
                <TableCell>有給使用数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.sub} hover>
                  <TableCell>
                    <Link
                      color='primary'
                      onClick={() => navigate('/attendManagement?userId=' + row.sub)}
                      sx={{ cursor: 'pointer' }}>
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell>{row.prescribedDateSum}</TableCell>
                  <TableCell>{row.nonattendanceDateSum}</TableCell>
                  <TableCell>{row.holidayAttendanceSum}</TableCell>
                  <TableCell>{row.earlyLeaveDateSum}</TableCell>
                  <TableCell>{row.lateAttendDateSum}</TableCell>
                  <TableCell>{row.totalActualWorkingTime}</TableCell>
                  <TableCell>{row.totalOverTime}</TableCell>
                  <TableCell>{row.usedPaidHolidayCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

function MobileRow(props) {
  const navigate = useNavigate();
  const { row, month } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(false);
  }, [month]);

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell>
          <Link color='primary' onClick={() => navigate('/attendManagement?userId=' + row.sub)}>
            {row.name}
          </Link>
        </TableCell>
        <TableCell align='right' sx={{ p: '0 16px 0 0', borderBottom: 'unset' }}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 0, mb: 2 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  <TableRow>
                    <TableCell>所定日数</TableCell>
                    <TableCell>{row.prescribedDateSum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>欠勤日数</TableCell>
                    <TableCell>{row.nonattendanceDateSum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>休日出勤日数</TableCell>
                    <TableCell>{row.holidayAttendanceSum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>早退日数</TableCell>
                    <TableCell>{row.earlyLeaveDateSum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>遅刻日数</TableCell>
                    <TableCell>{row.lateAttendDateSum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>総実労働時間</TableCell>
                    <TableCell>{row.totalActualWorkingTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>総残業時間</TableCell>
                    <TableCell>{row.totalOverTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>有給使用数</TableCell>
                    <TableCell>{row.usedPaidHolidayCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function UserStats() {
  const navigate = useNavigate();
  const { setIsLoading, isAdmin } = React.useContext(StoreProvider);
  const [userRows, setUserRows] = React.useState([]);
  // アラート
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('success');
  // 表示月
  const [month, setMonth] = React.useState('');
  // 月のリスト
  const [monthRows, setMonthRows] = React.useState([]);
  // モーダル
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlertOpen(false);
    setMessage('');
  };

  const handleOpen = (msg, status) => {
    setAlertOpen(true);
    setMessage(msg);
    setAlertStatus(status);
  };

  const setup = async () => {
    setIsLoading(true);

    // 管理者権限がなければリダイレクト
    if (!isAdmin) return navigate('/');

    // 月リストの設定
    _setMonthRow();

    // 表示月の設定
    const execMonth = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM');
    setMonth(execMonth);

    // ユーザーデータの設定
    await _setUserRows(execMonth);

    setIsLoading(false);
  };

  const _setUserRows = async (execMonth) => {
    try {
      // ユーザーリストの取得
      const userList = await getAllUsersApi(execMonth);
      setUserRows(userList);
    } catch (e) {
      console.error(e);
      handleOpen('ユーザー一覧情報を取得できませんでした', 'error');
    }
  };

  const _setMonthRow = () => {
    const startMonth = dayjs(process.env.REACT_APP_START_MONTH).startOf('month');
    const endMonth = dayjs().subtract(1, 'month').startOf('month');
    const monthDiff = endMonth.diff(startMonth, 'month');

    const rows = [];
    for (let i = 0; i <= monthDiff; i++) {
      rows.push(endMonth.subtract(i, 'month'));
    }
    setMonthRows(rows);
  };

  React.useEffect(() => {
    setup();
  }, []);

  React.useEffect(() => {
    if (month === '') return;
    _setUserRows(month);
  }, [month]);

  return (
    <App>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: '#ffffff',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 1 }}>
          <Box>
            <Button variant='outlined' onClick={() => setModalOpen(true)}>
              従業員追加
            </Button>
          </Box>
          <Box>
            <Select
              value={month}
              onChange={(event) => setMonth(event.target.value)}
              label='年月'
              variant='standard'>
              {monthRows.map((_month, index) => (
                <MenuItem key={index} value={_month.format('YYYY-MM')} selected={index === 0}>
                  {_month.format('YYYY年MM月')}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <RowTable month={month} rows={userRows} />
      </Box>

      <CreateUserModal
        handleAlertOpen={handleOpen}
        open={modalOpen}
        setOpen={setModalOpen}
        setUserRows={_setUserRows}
        month={month}
      />

      <AlertBar message={message} onClose={handleClose} open={alertOpen} status={alertStatus} />
    </App>
  );
}
