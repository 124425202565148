import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import SignIn from './pages/SignIn';
import reportWebVitals from './reportWebVitals';
import AuthGuard from './components/guard/AuthGuard';
import GuestGuard from './components/guard/GuestGuard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Attendance from './pages/Attendance';
import AttendManagement from './pages/AttendManagement';
import UserDetailConfig from './pages/UserDetailConfig';
import Spinner from './components/Spinner';
import UserStats from './pages/UserStats';
import { getAuthUserInfo } from './services/cognitoService';
import AttendanceStatus from './pages/AttendanceStatus';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';

// ローディングコンテキスト
export const StoreProvider = React.createContext();

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const initialize = async () => {
    const authUser = await getAuthUserInfo();
    if (authUser) setIsAdmin(Number(authUser['custom:role']) === 1);
    setIsReady(true);
  };

  React.useEffect(() => {
    initialize();
  }, []);

  return !isReady ? (
    <Spinner open={true} />
  ) : (
    <StoreProvider.Provider value={{ setIsLoading, isAdmin }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Spinner open={isLoading} />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route exact path='/' element={<AuthGuard component={<Attendance />} />} />
            <Route
              exact
              path='/attendManagement'
              element={<AuthGuard component={<AttendManagement />} />}
            />
            <Route
              exact
              path='/userDetailConfig'
              element={<AuthGuard component={<UserDetailConfig />} />}
            />
            <Route exact path='/userStats' element={<AuthGuard component={<UserStats />} />} />
            <Route
              exact
              path='/attendanceStatus'
              element={<AuthGuard component={<AttendanceStatus />} />}
            />
            <Route
              exact
              path='/signIn'
              element={<GuestGuard component={<SignIn initialize={initialize} />} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StoreProvider.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
