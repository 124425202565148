import { styled } from '@mui/material/styles';
import Logo from '../images/TiMic.svg';

const ImageComponent = styled('img')`
  height: 64px;
  width: 80px;
  object-fit: cover;
`;

export default function HeaderLogo() {
  return <ImageComponent src={Logo} alt='' />;
}
