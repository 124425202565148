import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { MenuItem, Select } from '@mui/material';
import App from '../components/App';
import AttendTable from '../components/attendManagement/AttendTable';
import AttendStats from '../components/attendManagement/AttendStats';
import { getDateListAndStatsApi } from '../services/apiService';
import { StoreProvider } from '..';
import { useSearchParams } from 'react-router-dom';
import AlertBar from '../components/AlertBar';

export default function AttendManagement() {
  const { setIsLoading } = React.useContext(StoreProvider);
  // 表示月
  const [month, setMonth] = React.useState('');
  // 表示月リスト
  const [monthRows, setMonthRows] = React.useState([]);
  // 現在のタブ
  const [tabValue, setTabValue] = React.useState('1');
  // 日毎の情報
  const [dateRows, setDateRows] = React.useState([]);
  // クエリパラメータ
  const [searchParams] = useSearchParams();
  // 勤怠統計
  const [statsValues, setStatsValues] = React.useState({});
  // アラート
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlertOpen(false);
    setMessage('');
  };

  const handleOpen = (msg, status) => {
    setAlertOpen(true);
    setMessage(msg);
    setAlertStatus(status);
  };

  const init = async () => {
    // 月リストの設定
    _setMonthRows();
    // 表示月の設定
    setMonth(dayjs().format('YYYY-MM'));

    const currentMonth = dayjs().format('YYYY-MM');
    setMonth(currentMonth);
  };

  const setup = async () => {
    setIsLoading(true);
    try {
      // 日付データと統計の取得
      const result = await getDateListAndStatsApi(month, searchParams.get('userId'));

      setDateRows(result.dateList);
      setStatsValues(result.stats);
    } catch (e) {
      console.error(e);
      handleOpen('勤怠データの取得に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const _setMonthRows = () => {
    const startMonth = dayjs(process.env.REACT_APP_START_MONTH).startOf('month');
    const endMonth = dayjs().startOf('month').add(1, 'month');
    const monthDiff = endMonth.diff(startMonth, 'month');

    const rows = [];
    for (let i = 0; i <= monthDiff; i++) {
      rows.push(endMonth.subtract(i, 'month'));
    }
    setMonthRows(rows);
  };

  // タブ切り替え
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // 初期設定
  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (month === '') return;
    setup();
  }, [month]);

  return (
    <App>
      <Box sx={{ backgroundColor: '#ffffff', width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label='lab API tabs example'>
              <Tab label='勤怠票' value='1' />
              <Tab label='勤怠統計' value='2' />
            </TabList>
          </Box>
          <Box sx={{ textAlign: 'right', m: 1 }}>
            <Select
              value={month}
              onChange={(event) => setMonth(event.target.value)}
              label='年月'
              variant='standard'>
              {monthRows.map((_month, index) => (
                <MenuItem key={index} value={_month.format('YYYY-MM')} selected={index === 0}>
                  {_month.format('YYYY年MM月')}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TabPanel value='1' sx={{ p: 0 }}>
            <AttendTable
              setup={setup}
              dateRows={dateRows}
              month={month}
              handleAlertOpen={handleOpen}
            />
          </TabPanel>
          <TabPanel value='2' sx={{ p: 0 }}>
            <AttendStats dateRows={dateRows} month={month} values={statsValues} />
          </TabPanel>
        </TabContext>
      </Box>
      <AlertBar message={message} onClose={handleClose} open={alertOpen} status={alertStatus} />
    </App>
  );
}
