import App from '../components/App';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import {
  addAttendApi,
  addLeaveApi,
  endBreakApi,
  getAttendanceApi,
  startBreakApi,
} from '../services/apiService';
import AlertBar from '../components/AlertBar';
import { StoreProvider } from '..';

dayjs.locale('ja');

const NOT_ATTEND = 0;
const ON_ATTEND = 1;
const LEAVING = 2;
const BREAKING = 3;

export default function Attendance() {
  const { setIsLoading } = React.useContext(StoreProvider);
  const [onTime, setOnTime] = React.useState(dayjs());
  const [attendanceState, setAttendanceState] = React.useState();
  //  0: 未打刻 1: 出勤中 2: 退勤済み
  const [attendStatus, setAttendStatus] = React.useState(LEAVING);
  // アラート
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState('');

  const setup = async () => {
    setIsLoading(true);
    await _setAttendStatus();
    setIsLoading(false);
  };

  /** 出退勤ステータスの設定 */
  const _setAttendStatus = async () => {
    setIsLoading(true);
    try {
      const result = await getAttendanceApi();

      let status = LEAVING;
      if (result) {
        const execDate = dayjs(result.ExecDate).startOf('day');
        const isToday = execDate.isValid() && execDate.isSame(dayjs().startOf('day'));
        if (isToday && (result.PaidHoliday || result.PublicHoliday)) {
          status = LEAVING;
        } else if (!result.StartTime) {
          status = NOT_ATTEND;
        } else if (result.EndTime) {
          // 処理日が当日なら退勤済み、それ以外は未打刻
          if (isToday) {
            status = LEAVING;
          } else {
            status = NOT_ATTEND;
          }
        }
        // 休憩中かどうか
        else if (result.isBreaking) {
          status = BREAKING;
        } else {
          status = ON_ATTEND;
        }
        setAttendanceState(result);
      } else {
        // 勤怠データがない = 未打刻
        status = NOT_ATTEND;
      }
      setAttendStatus(status);
    } catch (e) {
      console.error(e);
      handleOpen('出退勤情報の取得に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /** 出勤打刻 */
  const attend = async () => {
    setIsLoading(true);
    try {
      await addAttendApi({
        attendTime: dayjs().format('HH:mm'),
        execDate: dayjs().startOf('day').valueOf(),
      });

      handleOpen('出勤しました', 'success');
      setup();
    } catch (e) {
      console.error(e);
      handleOpen('出勤打刻に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /** 休憩開始打刻 */
  const startBreak = async () => {
    setIsLoading(true);
    try {
      await startBreakApi({
        attendanceId: attendanceState.AttendanceId,
        startBreakTime: dayjs().valueOf(),
      });

      handleOpen('休憩を開始しました', 'success');
      setup();
    } catch (e) {
      console.error(e);
      handleOpen('休憩開始打刻に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /** 休憩終了打刻 */
  const endBreak = async () => {
    setIsLoading(true);
    try {
      await endBreakApi({
        attendanceId: attendanceState.AttendanceId,
        endBreakTime: dayjs().valueOf(),
      });

      handleOpen('休憩を終了しました', 'success');
      setup();
    } catch (e) {
      console.error(e);
      handleOpen('休憩終了打刻に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  /** 退勤打刻 */
  const leave = async () => {
    setIsLoading(true);
    try {
      const data = {
        attendanceId: attendanceState.AttendanceId,
        leaveTime: dayjs().format('HH:mm'),
      };
      await addLeaveApi(data);

      handleOpen('退勤しました', 'success');
      setup();
    } catch (e) {
      console.error(e);
      handleOpen('退勤打刻に失敗しました', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
    setMessage('');
  };

  const handleOpen = (msg, status) => {
    setOpen(true);
    setMessage(msg);
    setAlertStatus(status);
  };

  React.useEffect(() => {
    // １秒毎に時刻を更新
    window.setInterval(() => {
      setOnTime(dayjs());
    }, 1000);

    setup();
  }, []);

  return (
    <App>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        item
        xs={12}
        sx={{
          backgroundColor: '#ffffff',
        }}>
        <Grid item container xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography component='p' variant='h6' sx={{ textAlign: 'center' }}>
              {`${onTime.format('M日D日(ddd)')}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component='p' variant='h4' sx={{ textAlign: 'center' }}>
              {onTime.format('HH:mm:ss')}
            </Typography>
          </Grid>

          <Grid item container xs={12} mt={5}>
            {attendStatus === BREAKING ? (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant='outlined' onClick={endBreak} sx={{ width: '90%', height: 50 }}>
                  休憩終了
                </Button>
              </Grid>
            ) : (
              <>
                {attendStatus === ON_ATTEND ? (
                  <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button
                      variant='outlined'
                      onClick={startBreak}
                      sx={{ width: '80%', height: 50 }}>
                      休憩開始
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button
                      variant='contained'
                      disabled={attendStatus !== NOT_ATTEND}
                      onClick={attend}
                      sx={{ width: '80%', height: 50 }}>
                      出勤
                    </Button>
                  </Grid>
                )}
                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                  <Button
                    variant='contained'
                    disabled={attendStatus !== ON_ATTEND}
                    onClick={leave}
                    sx={{ width: '80%', height: 50 }}>
                    退勤
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <AlertBar message={message} onClose={handleClose} open={open} status={alertStatus} />
    </App>
  );
}
