import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { FormControlLabel, Switch, Typography, colors } from '@mui/material';
import theme from '../../styles/theme';

export default function TimeField({ label, name, stateTuple, switchProps, error }) {
  const [state, setState] = stateTuple;
  const changeHandler = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Paper
      component='form'
      variant='outlined'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        borderColor: error[name] ? theme.palette.error.main : undefined,
      }}>
      <Typography
        p='10px'
        variant='body2'
        sx={{
          color: error[name] ? theme.palette.error.main : 'rgba(0, 0, 0, 0.6)',
        }}>
        {label}
      </Typography>
      <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
      <InputBase
        name={name}
        defaultValue={state[name]}
        onChange={changeHandler}
        sx={{ mx: 1, flex: 1, textAlign: 'center' }}
        placeholder='Search Google Maps'
        type='time'
      />
      {switchProps && (
        <>
          <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
          <FormControlLabel
            onChange={switchProps.onChange}
            control={<Switch color='primary' size='small' checked={switchProps.isLeavedTomorrow} />}
            label={<Typography variant='caption'>{switchProps.label}</Typography>}
            labelPlacement='top'
            sx={{ color: colors.grey[500], mx: 1, my: 0 }}
          />
        </>
      )}
    </Paper>
  );
}
