import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '../../CofirmDialog';
import TimeField from '../../common/TimeField';
import { StoreProvider } from '../../..';
dayjs.locale('ja');

const initialErrorState = {
  startTime: false,
  endTime: false,
  breakTime: false,
  comment: false,
};

export function EditModal(props) {
  const {
    openTuple,
    stateTuple,
    selectHolidayTuple,
    putAttendance,
    handleAlertOpen,
    deleteAttendance,
  } = props;
  const { isAdmin } = React.useContext(StoreProvider);

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [open, setOpen] = openTuple;
  const [state, setState] = stateTuple;
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [selectHoliday, setSelectHoliday] = selectHolidayTuple;

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  // バリデーション
  const validateField = () => {
    let result = initialErrorState;
    let errFlag = false;

    // 公休・有給が選択されていない場合の処理
    if (selectHoliday === '') {
      // 出勤時間がNULLならエラー
      if (!state.startTime) {
        result = {
          ...result,
          startTime: true,
        };
        errFlag = true;
      }
      // 出勤時間＞退勤時間＝エラー
      if (state.endTime && !state.isLeavedTomorrow) {
        const dateStr = dayjs().format('YYYY-MM-DD ');
        const startTime = dayjs(dateStr + state.startTime);
        const endTime = dayjs(dateStr + state.endTime);
        if (startTime.isAfter(endTime)) {
          result = {
            ...result,
            endTime: true,
          };
          errFlag = true;
        }
      }
      // 退勤時間がセットされている場合休憩時間がNULLならエラー
      if (state.endTime && !state.breakTime) {
        result = {
          ...result,
          breakTime: true,
        };
        errFlag = true;
      }
    }
    // 管理者権限ではない場合のバリデーション
    if (!isAdmin) {
      const comment = state.comment;
      if (!comment || comment?.trim() === '') {
        result = {
          ...result,
          comment: true,
        };
        handleAlertOpen('コメントの入力は必須です！', 'error');
        errFlag = true;
      }
    }
    setErrorState(result);
    return errFlag;
  };

  const updateHandler = async () => {
    if (validateField()) return;
    await putAttendance();
  };
  const openConfirmDialog = () => {
    setConfirmOpen(true);
  };
  const deleteAttendanceHandler = async () => {
    await deleteAttendance(state.attendanceId);
    setOpen(false);
  };
  React.useEffect(() => {
    setErrorState({
      ...errorState,
      startTime: false,
      endTime: false,
      breakTime: false,
      comment: false,
    });
  }, [open]);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle display='flex' flexDirection='row'>
          <Box flexGrow={1}>出勤データ修正</Box>
          {state.attendanceId && (
            <IconButton onClick={openConfirmDialog}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems='center' justify='center' spacing={2}>
            <Grid item xs={12}>
              <FormControl variant='standard' fullWidth>
                <InputLabel>公休・有給</InputLabel>
                <Select
                  value={selectHoliday}
                  label='公休・有給'
                  onChange={(event) => setSelectHoliday(event.target.value)}>
                  <MenuItem value={''}>未選択</MenuItem>
                  <MenuItem value={1}>公休</MenuItem>
                  <MenuItem value={2}>有給</MenuItem>
                  <MenuItem value={3}>半休(有給)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ m: 1 }} />
            <Grid item xs={12}>
              <TimeField
                label='出勤時間'
                name='startTime'
                stateTuple={[state, setState]}
                error={errorState}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeField
                label='退勤時間'
                name='endTime'
                stateTuple={[state, setState]}
                switchName='leaveDate'
                error={errorState}
                switchProps={{
                  label: '翌日',
                  onChange: (event) => {
                    setState({
                      ...state,
                      isLeavedTomorrow: event.target.checked,
                    });
                  },
                  isLeavedTomorrow: state.isLeavedTomorrow,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeField
                label='休憩時間'
                name='breakTime'
                error={errorState}
                stateTuple={[state, setState]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <TextField
              id='comment'
              margin='dense'
              label='コメント'
              fullWidth
              variant='standard'
              defaultValue={state.comment}
              error={errorState.comment}
              inputProps={{ maxLength: 255 }}
              onChange={handleChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 1.5 }}>
          <Grid justify='space-between' container>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setOpen(false)}
                sx={{ width: 125 }}>
                キャンセル
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                color='primary'
                onClick={updateHandler}
                sx={{ width: 125 }}>
                更新
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title='この勤怠データを削除しますか？'
        text='削除すると復元することはできません。'
        rightButtonText='削除する'
        stateTuple={[confirmOpen, setConfirmOpen]}
        onSubmit={deleteAttendanceHandler}
      />
    </>
  );
}
